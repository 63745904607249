import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MySwiper from "../components/swiper/swiper"
import SampleForm from "../components/form/form"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

const ProductItem = product => {
  console.log("Item -> product", product)
  const currencyToSymbol = currency => {
    let symbol = ""
    switch (currency) {
      case "usd":
        symbol = "$"
        break
      default:
        symbol = ""
        break
    }

    return symbol
  }

  return (
    <div className="col-12 mb-3">
      <h5>{product.name}</h5>{" "}
      {product.prices.map(price => (
        <div key={price.id}>
          <input
            id={price.id}
            type="checkbox"
            name={`webriq_payments[]`}
            value={JSON.stringify({
              provider: "stripe",
              type: "product",
              price: price.id,
            })}
            className="ml-4 mr-3"
          />
          <label htmlFor={price.id}>
            {price.name} - {currencyToSymbol(price.currency)} {price.amount}
          </label>
          <br />
        </div>
      ))}
    </div>
  )
}

const Products = () => {
  const [products, setProducts] = useState([])
  useEffect(() => {
    fetch(
      "https://staging-paymentsv2.webriq.com/accounts/5fa884d0-d35a-4406-8a7b-dbea9abba7ce/productsWithPrices"
    )
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setProducts(data)
      })
  }, [])

  return (
    <>
      <h2 className="mb-5">Products</h2>

      {products.map(product => (
        <ProductItem key={product.id} {...product} />
      ))}

      <br />
      <div className="col-12">
        One-time setup fee: $20.00
        <input
          type="hidden"
          name={`webriq_payments[]`}
          value={JSON.stringify({
            provider: "stripe",
            type: "one-off",
            name: "One-time Setup Fee",
            amount: 10,
            quantity: 1,
          })}
        />
      </div>
      <div className="col-12">
        Shipping Fee: $28.00
        <input
          type="hidden"
          name={`webriq_payments[]`}
          value={JSON.stringify({
            provider: "stripe",
            type: "one-off",
            name: "Shipping Fee",
            amount: 10,
            quantity: 1,
          })}
        />
      </div>
      <br />
      <br />
    </>
  )
}

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div>
          <br />
          <h1>WebriQ Form + Payment</h1>
          <br />
          <SampleForm
            form_name="Newsletter Form"
            form_id="686e7991-c98c-4e8b-acb8-07574c510520"
            scriptSrc="https://staging-forms.webriq.com/js/initReactForms"
            form_classname="form-newsletter"
          >
            <div className="form-row">
              <div className="col-12 mb-3">
                <div className="form-group">
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    id="email_address"
                  />
                  <label htmlFor="email_address">
                    Enter Your Email Address
                  </label>
                </div>
                <small className="text-dark form-text mt-2">
                  We will never share your email with anyone else.
                </small>
              </div>
              <div className="col-12 mb-3">
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    name="Name"
                    id="full_name"
                  />
                  <label htmlFor="full_name">Enter Your Name</label>
                </div>
              </div>

              <Products />

              <div className="col-12">
                <div className="webriq-recaptcha" />
              </div>

              <div className="col-12">
                <div className="form-group">
                  <button className="btn btn-primary hvr-shadow" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </SampleForm>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
